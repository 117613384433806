








import Vue from "vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default Vue.extend({
  components: { Breadcrumb },
});
